import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { App } from '@capacitor/app'
import { Grid, FormControl, InputLabel, Select } from '@material-ui/core'
import firebase from 'gatsby-plugin-firebase'

import { Page, View, Text, Input, Button } from 'lib'
import { useUserData } from './dashboard/_utils'
import theme from '_appSetup/Theme'
import { ALL_US_STATES } from './signup/_territories'

import '_appSetup/Global.css'

const TaxInfoPage = (props) => {
  const styles = useStyles(props)
  const [accountHolderName, setAccountHolderName] = useState('')
  const [SSN, setSSN] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [city, setCity] = useState('')
  const [USState, setUSState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [uploadStatus, setUploadStatus] = useState()

  const { UserData } = useUserData()

  useEffect(() => {
    if (UserData.taxInfo) {
      if (UserData.taxInfo.accountHolderName) setAccountHolderName(UserData.taxInfo.accountHolderName)
      if (UserData.taxInfo.SSN) setSSN(UserData.taxInfo.SSN)
      if (UserData.taxInfo.streetAddress) setStreetAddress(UserData.taxInfo.streetAddress)
      if (UserData.taxInfo.city) setCity(UserData.taxInfo.city)
      if (UserData.taxInfo.USState) setUSState(UserData.taxInfo.USState)
      if (UserData.taxInfo.zipCode) setZipCode(UserData.taxInfo.zipCode)
    } else {
      if (UserData.SSN) setSSN(UserData.SSN)
      if (UserData.businessName) {
        setAccountHolderName(UserData.businessName)
      } else if (UserData.firstName) {
        setAccountHolderName(`${UserData.firstName} ${UserData.lastName}`)
      } else if (UserData.fullName) {
        setAccountHolderName(UserData.fullName)
      }
    }
  }, [JSON.stringify(UserData)])


  const [valid, setValid] = useState({
    SSN: true,
  })


  const formIsValid = valid.SSN &&
  accountHolderName.length &&
    SSN.length &&
    streetAddress.length &&
    city.length &&
    USState.length &&
    zipCode.length


  const validate = (inputName, value) => {
    switch (inputName) {
      case 'SSN':
        setValid({
          ...valid,
          SSN: [0, 9].includes(value.length) &&
          !isNaN(parseFloat(value)),
        })
        break
      default:
        break
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    const processedValue = value

    switch (name) {
      case 'accountHolderName':
        setAccountHolderName(processedValue)
        break
      case 'SSN':
        setSSN(processedValue)
        break
      case 'streetAddress':
        setStreetAddress(processedValue)
        break
      case 'city':
        setCity(processedValue)
        break
      case 'USState':
        setUSState(value)
        break
      case 'zipCode':
        setZipCode(value)
        break
    }

    validate(name, processedValue)

    if (uploadStatus) setUploadStatus(null)
  }

  const saveTaxInfo = async () => {
    const taxInfoFunc = firebase.functions().httpsCallable('setTaxInfo')
    setUploadStatus('PENDING')
    await taxInfoFunc({ taxInfo: {
      accountHolderName,
      SSN,
      streetAddress,
      city,
      USState,
      zipCode,
    } })
    setUploadStatus('DONE')
  }

  return (
    <Page>
      <View style={styles.leftWrapper}>

        <Text variant='h5' gutterBottom style={styles.header}>Confirm your tax information</Text>

        <Text gutterBottom style={styles.textHeader}>Please read the details and disclaimers about the tax-related information we intend to provide <a href='https://stablegains.zendesk.com/hc/en-us/articles/10714130972433' target='_blank'>in our learning center</a>.</Text>

        <Grid container spacing={2} justify='flex-start'>

          <Grid item xs={12}>
            <Input
              value={accountHolderName}
              onChange={handleChange}
              name='accountHolderName'
              size='small'
              label={`Confirm your legal name`}
              required
              fullWidth
              autoFocus
            />

          </Grid>

          <Grid item xs={12}>
            <Input
              value={SSN}
              onChange={handleChange}
              name='SSN'
              size='small'
              label={`Your SSN (Social Security Number) or TIN`}
              required
              fullWidth
              error={!valid.SSN}
              helperText={`Enter 9 digits (without any dashes). If you don't have an SSN, enter your other relevant TIN (Tax Identification Number).`}
            />

          </Grid>

          <Grid item xs={12}>
            <Input
              value={streetAddress}
              onChange={handleChange}
              name='streetAddress'
              size='small'
              label={`Your street address (including apt. no.)`}
              required
              fullWidth
            />

          </Grid>

          <Grid item xs={12}>
            <Input
              value={city}
              onChange={handleChange}
              name='city'
              size='small'
              label={`City or town`}
              required
              fullWidth
            />

          </Grid>

          <Grid item xs={12}>
            <FormControl variant='outlined' size='small' fullWidth required>
              <InputLabel>State</InputLabel>
              <Select
                native
                value={USState}
                onChange={handleChange}
                name='USState'
                label={'State'}
              >
                <option aria-label='None' value=''/>
                {ALL_US_STATES.map(c => <option key={c.code} value={c.code}>{c.name}</option>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Input
              value={zipCode}
              onChange={handleChange}
              name='zipCode'
              size='small'
              label={`ZIP code`}
              required
              fullWidth
            />

          </Grid>
        </Grid>


        <Button
          disabled={!formIsValid || uploadStatus}
          style={styles.submitButton}
          variant='contained'
          size='large'
          color='secondary'
          text={uploadStatus == 'PENDING' ? 'Saving' : uploadStatus == 'DONE' ? 'Saved!' : 'Confirm' }
          onClick={saveTaxInfo}/>

      </View>
    </Page>
  )
}


const useStyles = makeStyles({
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginLeft: 0,
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(4),
  },
  opaque: {
    opacity: 0.5,
  },
  disclaimer: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
})

export default TaxInfoPage
